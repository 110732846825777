<template>
    <div class="pc-person-select-body">
        <div class="pc-person-select">
            <div class="pc-person-select-title">
                <div>角色信息</div>
            </div>
            <div class="pc-person-name">
                <div>{{personName}}</div>
            </div>
            <div class="pc-person-content">
                <div >
                    <img :src="personUrl">
                </div>
                <div>
                    <div>
                        <div class="avatar-list-item"  @click="chooseImg(k)" :class="{active:item.checked}" v-for="item,k in avatarList" :key="k">
                            <img :src="item.avatarUrl"/>
                        </div>
                    </div>
                </div>
            </div>
            <div class="pc-select-button">
                <div @click="confirmSelect"></div>
            </div>
        </div>
    </div>
</template>

<script>
import {getQueryVariable} from "@/js/tool";
export default {
    name: 'personSelectPc',
    data(){
        return{
            avatarList:[{
                id: 1,
                name:"角色1",
                avatarUrl: "https://qn.ky3d.com/person-select/Mesh3.png",
                personUrl: "https://qn.ky3d.com/person-select/3.png",
                personGlb: "https://qn.ky3d.com/person-select/Mesh3.glb",
                checked: true
            },{
                id: 2,
                name:"角色2",
                avatarUrl: "https://qn.ky3d.com/person-select/Mesh2.png",
                personUrl: "https://qn.ky3d.com/person-select/2.png",
                personGlb: "https://qn.ky3d.com/person-select/Mesh2.glb",
                checked: false
            },{
                id: 3,
                name:"角色3",
                avatarUrl: "https://qn.ky3d.com/person-select/Mesh1.png",
                personUrl: "https://qn.ky3d.com/person-select/1.png",
                personGlb: "https://qn.ky3d.com/person-select/Mesh1.glb",
                checked: false
            },{
                id: 4,
                name:"角色4",
                avatarUrl: "https://qn.ky3d.com/person-select/Mesh4.png",
                personUrl: "https://qn.ky3d.com/person-select/4.png",
                personGlb: "https://qn.ky3d.com/person-select/Mesh4.glb",
                checked: false
            },{
                id: 5,
                name:"角色5",
                avatarUrl: "https://qn.ky3d.com/person-select/Mesh5.png",
                personUrl: "https://qn.ky3d.com/person-select/5.png",
                personGlb: "https://qn.ky3d.com/person-select/Mesh5.glb",
                checked: false
            }],
            personName: "角色1",
            personUrl: "https://qn.ky3d.com/person-select/3.png",
            personGlb: "https://qn.ky3d.com/person-select/Mesh3.glb"
        }
    },
    created(){

    },
    mounted(){

    },
    methods:{
        chooseImg(index){
            this.avatarList.forEach((e,k)=>{
                if(index == k){
                    e.checked = true
                    this.personUrl = e.personUrl
                    this.personName = e.name
                    this.personGlb = e.personGlb
                }else{
                    e.checked = false
                }
            })
        },
        confirmSelect(){
            // console.log(234)
            this.$emit('showLoading');
            const tem_id = getQueryVariable("tem_id");
            console.log(tem_id)
            if(tem_id != 44 && tem_id != 43 ){
                const glb = this.personGlb
                // window.game.createCharacter(glb)
            }
            
            
        }

    }
}
</script>


<style>
    html,body {
        height: 100%;
        margin: 0px;
        padding: 0px;
        background-color: #04081A;
    }
    .pc-person-select-body{
        position: fixed;
        width: 100%;
        height: 100%;
        z-index: 9999;
        background-color: #04081A;
        display: flex;
        justify-content: center;
    }
    .pc-person-select-body > .pc-person-select{
        width: 1578px;
        height: 100%;
    }
    .pc-person-select-body > .pc-person-select > .pc-person-select-title{
        height: 110px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .pc-person-select-body > .pc-person-select > .pc-person-select-title > div{
        width: 145px;
        height: 34px;
        font-size: 36px;
        line-height: 34px;
        color: white;
    }
    .pc-person-select-body > .pc-person-select > .pc-person-name{
        width: 100%;
        height: 90px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .pc-person-select-body > .pc-person-select > .pc-person-name > div{
        width: 363px;
        height: 90px;
        line-height: 90px;
        text-align: center;
        color: white;
        font-size: 30px;
        border-radius: 5px;
        background-color: #101627;
        box-shadow: inset 0 0.5px 0.5px hsl(0deg 0% 100% / 24%);
    }
    .pc-person-select-body > .pc-person-select > .pc-person-content{
        width: 100%;
        height: 630px;
        display: flex;
        justify-content: space-between;
    }
    .pc-person-select-body > .pc-person-select > .pc-person-content > div{
        width: 50%;
        height: 630px;
    }
    .pc-person-select-body > .pc-person-select > .pc-person-content > div:first-child {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .pc-person-select-body > .pc-person-select > .pc-person-content > div:nth-child(2) {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .pc-person-select-body > .pc-person-select > .pc-person-content > div:nth-child(2) > div{
        width: 776px;
        height: 370px;
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        
    }
    .pc-person-select-body > .pc-person-select > .pc-person-content > div:nth-child(2) > div > .avatar-list-item{
        width: 169px;
        height: 169px;
        display: flex;
        background-color: #101627;
        box-shadow: inset 0 0.5px 0.5px hsl(0deg 0% 100% / 24%);
        align-items: flex-end;
        justify-content: center;
        margin-right: 31px;
        border-radius: 10px;
        cursor: pointer;
    }
    .pc-person-select-body > .pc-person-select > .pc-person-content > div:nth-child(2) > div > .avatar-list-item.active{
        border: 3px solid rgb(0,228,255);
    }

    .pc-person-select-body > .pc-person-select > .pc-person-content > div:nth-child(2) > div > .avatar-list-item:nth-child(4n){
        margin-right: 0px;
    }
    .pc-person-select-body > .pc-person-select > .pc-person-content > div:nth-child(2) > div > .avatar-list-item > img{
        width: 95px;
        height: 144px;
        object-fit: cover;        
    }
    .pc-person-select-body > .pc-person-select > .pc-select-button{
        width: 100%;
        height: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .pc-person-select-body > .pc-person-select > .pc-select-button > div{
        width: 520px;
        height: 150px;
        background: url('/bgi/person-select/pc-confirm-select-button.png') no-repeat center center;
        background-size: cover;
        cursor: pointer;
    }
</style>
